// @flow
import { initializeApp } from 'firebase/app';
//@TODO: delete firebase completely
import {
  Auth,
  getAuth,
  onAuthStateChanged,
  User as FirebaseUser,
  sendPasswordResetEmail,
  signOut,
  sendEmailVerification,
  updateEmail,
} from 'firebase/auth';
import {
  GDevelopAccountApi,
  GDevelopFirebaseConfig,
  GDevelopUserApi,
} from './ApiConfigs';
import axios from 'axios';
import { showErrorBox } from '../../UI/Messages/MessageBox';
import { type CommunityLinks } from './User';

export type Profile = {|
  id: string,
  appLanguage: string,
  email: string,
  isBoss: Boolean,
  lastLogin: string,
  lastVisitedStudio: string,
  name: string,
  notification: Object,
  profilePic: string,
  studios: Object[],
|};

export type Studio = {|
  selectedStudio: {|
    _id: string,
    name: string,
    slug: string,
    banner: string,
    logo: string,
    description: string,
    accountRole: string,
  |},
  studios: Array<{|
    _id: string,
    name: string,
    slug: string,
    banner: string,
    logo: string,
    description: string,
    accountRole: string,
  |}>,
|};
export type LoginForm = {|
  email: string,
  password: string,
|};

export type ForgotPasswordForm = {|
  email: string,
|};

export type RegisterForm = {|
  email: string,
  password: string,
  name: string,
  getNewsletterEmail: boolean,
|};

export type AdditionalUserInfoForm = {|
  gdevelopUsage?: string,
  teamOrCompanySize?: string,
  companyName?: string,
  creationExperience?: string,
  creationGoal?: string,
  hearFrom?: string,
|};

export type EditForm = {|
  username: string,
  description: string,
  getGameStatsEmail: boolean,
  getNewsletterEmail: boolean,
  donateLink: string,
  communityLinks: CommunityLinks,
|};

export type ChangeEmailForm = {|
  email: string,
|};

export type AuthError = {
  code:
    | 'auth/invalid-email'
    | 'auth/user-disabled'
    | 'auth/user-not-found'
    | 'auth/wrong-password'
    | 'auth/email-already-in-use'
    | 'auth/operation-not-allowed'
    | 'auth/weak-password'
    | 'auth/username-used'
    | 'auth/malformed-username'
    | 'auth/requires-recent-login'
    | 'auth/too-many-requests',
};

export default class Authentication {
  auth: Auth;
  _onUserLogoutCallbacks: Array<() => void | Promise<void>> = [];
  _onUserUpdateCallbacks: Array<() => void | Promise<void>> = [];

  constructor() {
    const app = initializeApp(GDevelopFirebaseConfig);
    this.auth = getAuth(app);
    onAuthStateChanged(this.auth, user => {
      if (user) {
        // User has logged in or changed.
        this._onUserUpdateCallbacks.forEach(cb => cb());
      } else {
        // User has logged out.
        this._onUserLogoutCallbacks.forEach(cb => cb());
      }
    });
  }

  addUserLogoutListener = (cb: () => void | Promise<void>) => {
    this._onUserLogoutCallbacks.push(cb);
  };

  addUserUpdateListener = (cb: () => void | Promise<void>) => {
    this._onUserUpdateCallbacks.push(cb);
  };

  removeEventListener = (cbToRemove: () => void | Promise<void>) => {
    this._onUserLogoutCallbacks = this._onUserLogoutCallbacks.filter(
      cb => cb !== cbToRemove
    );
    this._onUserUpdateCallbacks = this._onUserUpdateCallbacks.filter(
      cb => cb !== cbToRemove
    );
  };

  createStudio = (form: RegisterForm): Promise<void> => {
    return axios.post(`${GDevelopAccountApi.baseUrl}/create`, {
      ...form,
    });
  };

  login = (form: LoginForm): Promise<void> => {
    return axios.post(`${GDevelopAccountApi.baseUrl}/login`, {
      ...form,
    });
  };

  forgotPassword = (form: ForgotPasswordForm): Promise<void> => {
    return sendPasswordResetEmail(this.auth, form.email);
  };

  getFirebaseUser = async (): Promise<?FirebaseUser> => {
    const { currentUser } = this.auth;
    if (!currentUser) {
      return null;
    }

    // In order to fetch the latest firebaseUser properties (like emailVerified)
    // we have to call the reload method.
    await currentUser.reload();
    return this.auth.currentUser;
  };

  sendFirebaseEmailVerification = async (): Promise<void> => {
    {
      const { currentUser } = this.auth;
      if (!currentUser)
        throw new Error(
          'Tried to send verification email while not authenticated.'
        );

      await currentUser.reload();
    }
    const { currentUser } = this.auth;
    if (!currentUser || currentUser.emailVerified) return;

    try {
      await sendEmailVerification(currentUser);
    } catch (error) {
      showErrorBox({
        message:
          'An email has been sent recently, check your inbox or please try again later.',
        rawError: error,
        errorId: 'email-verification-send-error',
      });
    }
  };

  changeEmail = async (
    getAuthorizationHeader: () => Promise<string>,
    form: ChangeEmailForm
  ) => {
    const { currentUser } = this.auth;
    if (!currentUser)
      throw new Error('Tried to change email while not authenticated.');

    return updateEmail(currentUser, form.email)
      .then(() => sendEmailVerification(currentUser))
      .then(() => {
        console.log('Email successfully changed in Firebase.');
        return getAuthorizationHeader();
      })
      .then(authorizationHeader => {
        return axios.patch(
          `${GDevelopUserApi.baseUrl}/user/${currentUser.uid}`,
          {
            email: form.email,
          },
          {
            params: {
              userId: currentUser.uid,
            },
            headers: {
              Authorization: authorizationHeader,
            },
          }
        );
      })
      .then(() => {
        console.log('Email successfully changed in the GDevelop services.');
      })
      .catch(error => {
        console.error('An error happened during email change.', error);
        throw error;
      });
  };

  getUserProfile = async (
    getAuthorizationHeader: () => Promise<string>
  ): Promise<Profile> => {
    //@TODO: logout for 4xx errors
    const auth = await getAuthorizationHeader();
    try {
      const res = await axios.get(
        `${GDevelopAccountApi.baseUrl}/getAccount?editor=true`,
        {
          headers: { Authorization: auth },
        }
      );
      const data = res.data.data;
      localStorage.setItem(
        '3engine_data',
        'W1' +
          btoa(
            JSON.stringify({
              gameAuthToken: data.gameAuthToken,
              storeAuthToken: data.storeAuthToken,
            })
          ) +
          'V5Sm'
      );
      return {
        data: {
          appLanguage: data.appLanguage,
          email: data.email,
          isBoss: data.isBoss,
          lastLogin: data.lastLogin,
          id: data._id,
          lastVisitedStudio: data.lastVisitedStudio,
          name: data.name,
          notification: data.notification,
          profilePic: data.profilePic,
          studios: data.studios,
        },
      };
    } catch (error) {
      console.log('userProfile.data', error);
      return error.response;
    }
  };

  editUserProfile = async (
    getAuthorizationHeader: () => Promise<string>,
    {
      username,
      description,
      getGameStatsEmail,
      getNewsletterEmail,
      appLanguage,
      isCreator,
      donateLink,
      communityLinks,
      gdevelopUsage,
      teamOrCompanySize,
      companyName,
      creationExperience,
      creationGoal,
      hearFrom,
    }: {
      username?: string,
      description?: string,
      getGameStatsEmail?: boolean,
      getNewsletterEmail?: boolean,
      appLanguage?: string,
      isCreator?: boolean,
      donateLink?: string,
      communityLinks?: CommunityLinks,
      gdevelopUsage?: string,
      teamOrCompanySize?: string,
      companyName?: string,
      creationExperience?: string,
      creationGoal?: string,
      hearFrom?: string,
    }
  ) => {
    const { currentUser } = this.auth;
    if (!currentUser)
      throw new Error('Tried to edit user profile while not authenticated.');

    return getAuthorizationHeader()
      .then(authorizationHeader => {
        return axios.patch(
          `${GDevelopUserApi.baseUrl}/user/${currentUser.uid}`,
          {
            username,
            description,
            getGameStatsEmail,
            getNewsletterEmail,
            appLanguage,
            isCreator,
            donateLink,
            communityLinks,
            gdevelopUsage,
            teamOrCompanySize,
            companyName,
            creationExperience,
            creationGoal,
            hearFrom,
          },
          {
            params: {
              userId: currentUser.uid,
            },
            headers: {
              Authorization: authorizationHeader,
            },
          }
        );
      })
      .then(response => response.data)
      .catch(error => {
        console.error('Error while editing user:', error);
        throw error;
      });
  };

  acceptGameStatsEmail = async (
    getAuthorizationHeader: () => Promise<string>
  ) => {
    const { currentUser } = this.auth;
    if (!currentUser)
      throw new Error(
        'Tried to accept game stats email while not authenticated.'
      );

    return getAuthorizationHeader()
      .then(authorizationHeader => {
        return axios.patch(
          `${GDevelopUserApi.baseUrl}/user/${currentUser.uid}`,
          { getGameStatsEmail: true },
          {
            params: { userId: currentUser.uid },
            headers: { Authorization: authorizationHeader },
          }
        );
      })
      .then(response => response.data)
      .catch(error => {
        console.error('Error while accepting game stats email:', error);
        throw error;
      });
  };

  getFirebaseUserSync = (): ?FirebaseUser => {
    return this.auth.currentUser || null;
  };

  logout = async () => {
    try {
      await signOut(this.auth);
      console.log('Logout successful.');
    } catch (error) {
      console.error('An error happened during logout.', error);
      throw error;
    }
  };

  deleteAccount = async (getAuthorizationHeader: () => Promise<string>) => {
    const { currentUser } = this.auth;
    if (!currentUser) {
      throw new Error('Tried to delete account while not authenticated.');
    }

    try {
      const authorizationHeader = await getAuthorizationHeader();
      await axios.delete(`${GDevelopUserApi.baseUrl}/user/${currentUser.uid}`, {
        params: {
          userId: currentUser.uid,
        },
        headers: {
          Authorization: authorizationHeader,
        },
      });
      // Ensure we logout the user after the account has been deleted.
      await this.logout();
    } catch (error) {
      console.error('An error happened during account deletion.', error);
      throw error;
    }
  };

  getAuthorizationHeader = () => {
    return new Promise<string>((resolve, reject) => {
      setTimeout(() => {
        const token = localStorage.getItem('3engine_token');
        if (!token) {
          this.auth.currentUser = null;
          reject('User is not authenticated.');
        }

        resolve(`Bearer ${token}`);
      }, 100);
    });
  };

  isAuthenticated = (): boolean => {
    return !!this.auth.currentUser;
  };
}
