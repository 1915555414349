// This file is generated by compile-translations.js script
// prettier-ignore
module.exports = [
  {
    "languageCode": "en",
    "languageName": "English",
    "languageNativeName": "English",
    "translationRatio": 0.056280587275693295
  },
  {
    "languageCode": "ru_RU",
    "languageName": "Russian",
    "languageNativeName": "Русский",
    "translationRatio": 0.9337579617834395
  }
];
